<script lang="ts" setup>
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper';
import type { Ref } from 'vue';
import type { Galleries } from '~/types/MainPage';
import type { Swiper as SwiperType } from 'swiper/types';

defineProps<{ data: Galleries }>();

onMounted(() => {
  sliderInitialization();
});

const mainBannerSwiper: Ref<SwiperType | null> = ref(null);
const mainBannerSwiperRef = ref();
const swiperPaginationBlock = ref();

function sliderInitialization() {
  mainBannerSwiper.value = new Swiper(mainBannerSwiperRef.value, {
    autoplay: true,
    centeredSlides: true,
    modules: [Autoplay, Navigation, Pagination],
    navigation: {
      prevEl: '.main-banner-gallery__slider-navigation-btn--prev',
      nextEl: '.main-banner-gallery__slider-navigation-btn--next',
    },
    pagination: {
      clickable: true,
      el: swiperPaginationBlock.value,
      type: 'bullets',
    },
    rewind: true,
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 1000,
  });
}
</script>

<template>
  <section class="main-banner-gallery">
    <section
      ref="mainBannerSwiperRef"
      class="swiper w-full"
    >
      <div class="swiper-wrapper">
        <AppLink
          v-for="image in data.gallery_mobile"
          :key="image.full"
          :to="makeUrlRelative(image.url)"
          class="swiper-slide main-banner-gallery__image !block md:!hidden"
        >
          <picture class="flex place-content-center size-full">
            <source
              :srcset="`${image.thumb_webp.thumb_350}, ${image.thumb_webp.thumb_700} 2x`"
              type="image/webp"
            />
            <source
              :srcset="image.thumb.thumb_350"
              type="image/jpeg"
            />
            <img
              :alt="image.alt"
              :src="image.thumb.thumb_350 || image.full"
              class="object-contain rounded-2xl overflow-hidden"
            />
          </picture>
        </AppLink>

        <AppLink
          v-for="image in data.gallery_desktop"
          :key="image.full"
          :to="makeUrlRelative(image.url)"
          class="swiper-slide main-banner-gallery__image !hidden md:!block"
        >
          <picture class="flex place-content-center size-full">
            <source
              :srcset="`${image.thumb_webp.thumb_1200}, ${image.thumb_webp.thumb_2400} 2x`"
              class="object-contain"
              type="image/webp"
            />
            <source
              :srcset="image.thumb.thumb_1200"
              class="object-contain"
              type="image/jpeg"
            />
            <img
              :src="image.thumb.thumb_1200 || image.full"
              alt=""
              class="object-contain rounded-2xl overflow-hidden"
            />
          </picture>
        </AppLink>
      </div>

      <section
        v-if="data.gallery_desktop.length > 1"
        class="swiper-navigation"
      >
        <div class="swiper-button swiper-button--prev main-banner-gallery__slider-navigation-btn--prev">
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </div>

        <div class="swiper-button swiper-button--next main-banner-gallery__slider-navigation-btn--next">
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </div>
      </section>

      <section
        ref="swiperPaginationBlock"
        class="swiper-pagination"
      />
    </section>
  </section>
</template>

<style lang="scss">
.main-banner-gallery {
  @include inner-content;

  &__image {
    position: relative;
    overflow: hidden;
    width: 100% !important;
  }
}
</style>
